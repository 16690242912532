.addNote {
  position: fixed;
  left: 500px;
  flex-direction: column
}

.formGroup {
  display: flex;
  flex-direction: column;
}

input {
  margin-bottom : 10px;
}


.add-note-content-control {
  height: 80px;
  width: 500px
}
